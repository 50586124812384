import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  recommendations: {
    dv: {
      slots: {
        1: 'searchProductToProducts',
        3: null,
        4: 'searchReplace',
      },
    },
    atb: {
      slots: {
        1: 'searchBasketToProducts',
        2: null,
      },
    },
  },
  cliplister: {
    customerId: '81856',
  },
  flixmedia: {
    distributorId: 18441,
    enabled: true,
  },
  hasKIM: false,
};
